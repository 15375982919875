<template>
  <section
    id="product_description"
    class="grid-zone"
    :class="{ 'no-description': !description, }"
  >
    <div
      v-if="description"
      id="description"
    >
      <h2 class="s4 bold">
        {{ $t('produit.description') }}
      </h2>
      <div
        class="text-regular"
        v-html="description"
      />
    </div>

    <div id="caracteristiques">
      <h2 class="s4 bold">
        {{ $t('produit.caracteristiques') }}
      </h2>
      <ul
        id="liste_caract"
        class="text-regular"
      >
        <li
          v-for="carac in caracteristiques"
          :key="carac.label"
        >
          <span>{{ carac.label }}</span>
          <span>{{ carac.value }}</span>
        </li>

        <template v-if="product.articles_papier && product.articles_papier.length">
          <hr />
          <li>
            <span>{{ $t('produit.version-papier') }}</span>
            <ul>
              <li
                v-for="livre in product.articles_papier"
                :key="livre.id"
              >
                <router-link
                  v-if="hasPerm('view_articlepapier')"
                  :to="{ name: 'catalogues_livres_manuels_papier_item', params: { id: livre.id, }, }"
                >
                  {{ livre.ean }}
                </router-link>
                <span v-else>{{ livre.ean }}</span>
              </li>
            </ul>
          </li>
        </template>

        <template v-else-if="product.manuels_numeriques && product.manuels_numeriques.length">
          <hr />
          <li>
            <span>{{ $t('produit.version-numerique') }}</span>
            <ul>
              <li
                v-for="manuelNum in product.manuels_numeriques"
                :key="manuelNum.id"
              >
                <router-link :to="{ name: 'catalogues_ressources_numeriques_item', params: { id: manuelNum.id, }, }">
                  {{ manuelNum.ean }}
                </router-link>
              </li>
            </ul>
          </li>
        </template>
      </ul>
    </div>
  </section>
</template>

<script>
import mixinDisponibilite from "@/mixins/mixinDisponibilite";

import { mapGetters } from "vuex";

/**
 * Affiche la description et les caractéristiques d'un produit.
 */
export default {
  name: "ProductDescription",
  mixins: [mixinDisponibilite],
  props: {
    /**
     * Produit affiché.
     */
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["isGE", "hasPerm", "user"]),
    description() {
      return (this.product.description || this.product.extrait)?.split("\n")?.join("<br />")?.split("\r")?.join("");
    },
    variant() {
      return this.product.offre_defaut?.manuel_numerique ? "numerique" : "papier";
    },
    caracteristiques() {
      // Liste des caractéristiques
      let carac = [{ label: this.$t("produit.ean"), value: this.product.ean }];

      if (this.variant === "numerique") {
        carac = [
          ...carac,
          { label: this.$t("produit.code-ark"), value: this.product.code_ark },
          { label: this.$t("produit.editeur"), value: this.product?.editeur?.nom },
          { label: this.$t("produit.reference-editeur"), value: this.product.reference_editeur },
          { label: this.$t("produit.collection"), value: this.product?.collection?.libelle },
        ];

        let categorie = null;
        if (this.product.categorie === 20) {
          categorie = this.$t("produit.manuel-numerique");
        } else if (this.product.categorie === 21) {
          categorie = this.$t("produit.ressource-numerique");
        }
        carac.push({ label: this.$t("produit.categorie"), value: categorie });

        carac = [
          ...carac,
          { label: this.$t("produit.discipline-s"), value: this.matieres },
          { label: this.$t("produit.niveau-x"), value: this.niveaux },
          { label: this.$t("produit.classe-s"), value: this.classes },
          { label: this.$t("produit.public"), value: this.publics },
          { label: this.$t("produit.technologie"), value: this.product.technologie?.libelle },
        ];

        if (this.firstSupport) {
          let typeLicenceGar = null;
          let title = null;
          if (this.firstSupport.type_licence_gar === "T") {
            typeLicenceGar = this.$t("produit.transferable");
            title = this.$t("produit.licence-desafectee-puis-reafectee-sans-conditions");
          } else if (this.firstSupport.type_licence_gar === "NT") {
            typeLicenceGar = this.$t("produit.non-transferable");
            title = this.$t("produit.licence-ne-peut-etre-desaffectee-une-fois-affectee");
          } else if (this.firstSupport.type_licence_gar === "F") {
            typeLicenceGar = this.$t("produit.flottante");
            title = this.$t("produit.licence-peut-etre-desaffectee-puis-reaffectee-sous-conditions-gar");
          }

          carac = [
            ...carac,
            { label: this.$t("produit.version-du-lecteur"), value: this.firstSupport.version_reader },
            { label: this.$t("produit.type-de-licence-gar"), value: typeLicenceGar, title },
          ];

          if (this.firstSupport.nb_max_install && this.hasPerm("can_see_additional_infos")) {
            carac = [
              ...carac,
              {
                label: this.$t("produit.nombre-maximum-installations"),
                value: this.firstSupport.nb_max_install === -1
                  ? this.$t("produit.illimite")
                  : this.firstSupport.nb_max_install,
              },
            ];
          }

          if (this.firstSupport.nb_max_connexions_simultanees && this.hasPerm("can_see_additional_infos")) {
            carac = [
              ...carac,
              {
                label: this.$t("produit.nombre-de-connexions-simultanees-autorisees"),
                value: this.firstSupport.nb_max_connexions_simultanees === -1
                  ? this.$t("produit.illimite")
                  : this.firstSupport.nb_max_connexions_simultanees,
              },
            ];
          }
        }
      } else {
        carac = [
          ...carac,
          { label: this.$t("produit.editeur"), value: this.product?.editeur?.nom },
          { label: this.$t("produit.reference-editeur"), value: this.product.ref_editeur },
          { label: this.$t("produit.collection"), value: this.collections },
          { label: this.$t("produit.discipline-s"), value: this.matieres },
          { label: this.$t("produit.niveau-x"), value: this.niveaux },
          { label: this.$t("produit.classe-s"), value: this.classes },
          { label: this.$t("produit.public"), value: this.publics },
          { label: this.$t("produit.format"), value: this.product.format },
          { label: this.$t("produit.langue"), value: this.product?.langue?.libelle },
        ];
      }

      // Gestion de la date de publication
      // La date doit être renseignée et valide (ni vide ni '00/00/0000' par exemple)
      const datePubliMoment = this.$moment(this.product?.date_publication).isValid()
        ? this.$moment(this.product.date_publication)
        : null;

      // La date doit être dans moins de 2 ans
      const datePubli = datePubliMoment?.isSameOrBefore(this.$moment().add(2, "years"))
        ? datePubliMoment
        : null;

      const isOperateur = false;

      if (this.product?.offre_defaut?.code_disponibilite === 2) {
        // Si la date est passée, on ne l'affiche pas
        if (this.$moment(datePubli).isAfter(this.$moment(), "days") || isOperateur) {
          carac.push({
            label: this.$t("produit.date-de-publication"),
            value: datePubli?.format(this.$t("global.format-date")),
          });
        }
      } else if (this.$moment(datePubli).isBefore(this.$moment(), "days") || isOperateur) {
        // Si la date est passée, on l'affiche
        carac.push({
          label: this.$t("produit.date-de-publication"),
          value: datePubli?.format(this.$t("global.format-date")),
        });
      }

      // Gestion de la disponibilité
      if (this.computedIsProductAvailable) {
        carac.push({
          label: this.$t("produit.disponibilite"),
          value: this.libelleDispo,
        });
      }

      // On n'affiche pas les caractéristiques non remplies
      return carac.filter((c) => c.value);
    },
    matieres() {
      return this.product?.matieres?.map((c) => c.libelle).filter((c) => c).join(", ");
    },
    niveaux() {
      return this.product?.niveaux?.map((c) => c.libelle).filter((c) => c).join(", ");
    },
    publics() {
      return this.product?.publics?.map((c) => c.libelle).filter((c) => c).join(", ");
    },
    classes() {
      return this.product?.classes?.map((c) => c.libelle).filter((c) => c).join(", ");
    },
    collections() {
      return this.product?.collections?.map((c) => c.libelle).filter((c) => c).join(", ");
    },
    firstSupport() {
      if (this.product.technologie) {
        return this.product.technologie.supports.find((sup) => sup.support.libelle === "Windows");
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/products/product_description.scss";
</style>
