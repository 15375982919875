<template>
  <div id="product_features">
    <template v-if="nonNullFeatures.length">
      <div id="zone_button">
        <ButtonClassic
          data-bs-toggle="collapse"
          data-bs-target="#tab_features"
          variant="special"
          color="primary"
          :label="`${visible ? $t('general.masquer') : $t('general.afficher')} ${labelText}`"
          icon="right"
          @click="visible = !visible"
        >
          <template #right-icon>
            <UilLayerGroup />
          </template>
        </ButtonClassic>
      </div>
      <div
        id="tab_features"
        class="collapse"
      >
        <table>
          <thead class="text-medium bold">
            <tr>
              <th>{{ $t('produit.fonctionnalites') }}*</th>
              <th
                v-for="support in orderedSupports"
                :key="support.id"
              >
                {{ support ? support.support.libelle : "N.A." }}
              </th>
            </tr>
          </thead>
          <tbody class="text-regular">
            <tr
              v-for="feat in nonNullFeatures"
              :key="feat.key"
            >
              <td class="flex-vcenter">
                {{ feat.label }}
                <UilInfoCircle
                  v-if="feat.tooltip"
                  v-tooltip="{
                    content: feat.tooltip,
                    theme: 'blue',
                    delay: { show: 800, },
                  }"
                />
              </td>
              <td
                v-for="support in orderedSupports"
                :key="support.id"
              >
                <span :class="['circle', colors[support[feat.key]], ]" />
              </td>
            </tr>
          </tbody>
        </table>
        <p
          v-if="product.technologie"
          class="text-small italic"
        >
          *{{ $t('produit.fonctionnalites-helper', { libelle: product.technologie.libelle, }) }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import { ButtonClassic } from "@lde/core_lde_vue";

import { UilLayerGroup, UilInfoCircle } from "@iconscout/vue-unicons";

/**
 * Affiche les fonctionnalités liées à la technologie du produit.
 * Utilise BootstrapVue [Collapse](https://bootstrap-vue.org/docs/components/collapse).
 */
export default {
  name: "ManuelNumeriqueFeatures",
  components: {
    ButtonClassic,
    UilLayerGroup,
    UilInfoCircle,
  },
  props: {
    /**
     * Produit affiché.
     */
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      labelText: this.$t("produit.fonctionnalites-liees-a-technologie"),
      visible: false,
      features: [
        { key: "export_cle_usb", label: this.$t("produit.export-cle-usb") },
        { key: "deploiement_masse", label: this.$t("produit.deploiement-en-masse") },
        { key: "web_adaptatif", label: this.$t("produit.feats.web-adaptif") },
        { key: "parcours_eleve_personnalise", label: this.$t("produit.parcours-eleve-personnalise") },
        { key: "annotations", label: this.$t("produit.feats.annotations") },
        { key: "creation_cours", label: this.$t("produit.creation-de-cours") },
        { key: "marque_page", label: this.$t("produit.feats.marque-pages") },
        { key: "capture_image", label: this.$t("produit.feats.cature-image") },
        { key: "zoom", label: this.$t("produit.feats.zoom-dans-app") },
        { key: "fonctions_recherche", label: this.$t("produit.feats.fonction-recherche") },
        { key: "gestion_groupes_eleves", label: this.$t("produit.gestion-de-groupes-eleves") },
        { key: "assignation_taches_eleves", label: this.$t("produit.assignation-de-taches-aux-eleves") },
        { key: "partage_contenu_eleves", label: this.$t("produit.partage-de-contenu-aux-eleves") },
        {
          key: "personnalisation_contenu_enseignant",
          label: this.$t("produit.personnalisation-contenus-par-enseignant"),
        },
        { key: "edition_formules_mathematiques", label: this.$t("produit.edition-formules-mathematiques") },
        { key: "correction_travail_par_enseignant", label: this.$t("produit.correction-travail-eleve-par-enseignant") },
        { key: "suivi_eleve", label: this.$t("produit.suivi-eleve-score-progression") },
        { key: "exercices_interactifs", label: this.$t("produit.feats.exercices-interactifs") },
        { key: "corriges_pour_enseignants", label: this.$t("produit.corriges-pour-enseignants") },
        { key: "exercices_auto_corriges", label: this.$t("produit.feats.exercices-auto-corriges") },
        { key: "import_document", label: this.$t("produit.import-de-document") },
        { key: "export_reponses_eleves", label: this.$t("produit.feats.export-reponses-eleves") },
        { key: "export_document", label: this.$t("produit.feats.exports-documents") },
        { key: "export_scorm", label: this.$t("produit.export-scorm") },
        { key: "personnalisation_user_interface", label: this.$t("produit.feats.personnalisation-interface") },
        { key: "dispositif_dys", label: this.$t("produit.feats.adaptation-contenu-dys") },
        { key: "messagerie", label: this.$t("produit.messagerie-tchat") },
      ],
      colors: {
        0: "red",
        1: "green",
        2: "orange",
      },
    };
  },
  computed: {
    orderedSupports() {
      // TODO: A voir si c'est important d'avoir le même ordre que la maquette
      if (this.product.technologie) {
        const { supports } = this.product.technologie;

        const smartphones = [];
        const smartphoneIOS = supports.find((s) => s.support.libelle === "Smartphone iOS");
        const smartPhoneAndroid = supports.find((s) => s.support.libelle === "Smartphone Android");
        if (smartphoneIOS || smartPhoneAndroid) {
          if (smartphoneIOS) {
            smartphones.push(smartphoneIOS);
          }
          if (smartPhoneAndroid) {
            smartphones.push(smartPhoneAndroid);
          }
        } else {
          smartphones.push(supports.find((s) => s.support.libelle === "Smartphone"));
        }
        const res = [
          supports.find((s) => s.support.libelle === "Windows"),
          supports.find((s) => s.support.libelle === "MacOS"),
          supports.find((s) => s.support.libelle === "Linux"),
          supports.find((s) => s.support.libelle === "iOS tablette"),
          supports.find((s) => s.support.libelle === "Android tablette"),
          ...smartphones,
        ];

        return res.filter((s) => s !== null && s !== undefined);
      }
      return null;
    },
    nonNullFeatures() {
      return this.features.filter((feat) => this.orderedSupports?.some((s) => {
        const supportData = s[feat.key];
        // Si supportData === 0, la donnée en question n'est pas à prendre en compte car possiblement pas renseigné.
        // https://app.clickup.com/t/86bydfuq4
        return supportData !== null && supportData !== 0;
      }));
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/manuels_numeriques/manuel_numerique_features.scss";
</style>
