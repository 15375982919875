<template>
  <section id="product_required">
    <h2
      v-if="orderedSupports && firstSupport && parseInt(product.poids_fichier)"
      class="s4 bold"
    >
      {{ $t('produit.requis') }}
    </h2>
    <div>
      <ul class="text-regular">
        <template v-if="orderedSupports">
          <li v-if="orderedSupports['Windows']['configuration_mini_os']">
            {{ orderedSupports["Windows"]["configuration_mini_os"].indexOf("Windows") === -1
              ? "Windows " + orderedSupports["Windows"]["configuration_mini_os"]
              : orderedSupports["Windows"]["configuration_mini_os"] }}
          </li>
          <li v-if="orderedSupports['MacOS']['configuration_mini_os']">
            {{ orderedSupports["MacOS"]["configuration_mini_os"].toLowerCase().indexOf("macos") === -1
              ? "MacOs " + orderedSupports["MacOS"]["configuration_mini_os"]
              : orderedSupports["MacOS"]["configuration_mini_os"] }}
          </li>
          <li v-if="orderedSupports['Android tablette']['configuration_mini_os']">
            {{ orderedSupports["Android tablette"]["configuration_mini_os"].toLowerCase().indexOf("android") === -1
              ? "Android " + orderedSupports["Android tablette"]["configuration_mini_os"]
              : orderedSupports["Android tablette"]["configuration_mini_os"] }}
          </li>
          <li v-if="orderedSupports['iOS tablette']['configuration_mini_os']">
            {{ orderedSupports["iOS tablette"]["configuration_mini_os"].toLowerCase().indexOf("ios") === -1
              ? "iOS " + orderedSupports["iOS tablette"]["configuration_mini_os"]
              : orderedSupports["iOS tablette"]["configuration_mini_os"] }}
          </li>
        </template>
        <li v-if="firstSupport && firstSupport.configuration_mini_navigateur">
          {{ $t('produit.configuration-minimale-navigateur') }} {{ firstSupport.configuration_mini_navigateur }}
        </li>
        <li v-if="parseInt(product.poids_fichier)">
          {{ $t('produit.espace-telechargement') }}{{ "\xa0" }}:
          <span
            v-html="product.poids_fichier
              ? $t('produit.poids-fichier', { poids: Math.round(product.poids_fichier * 1.05), })
              : $t('general.inconnu')"
          />
        </li>
      </ul>
      <ButtonClassic
        v-if="!isGE"
        v-tooltip="{
          content: $tc('general.mail-copie-info', 0, { mail: mailLde, } ),
          show: isDe,
          placement: 'top',
          delay: { show: 800, },
        }"
        v-bind="btnAssistanceAttr"
        :label="$t('action.contactez-nous-pour-toute-question')"
        variant="special"
        color="primary"
        icon="right"
        @click="copyMailLde()"
      >
        <template #right-icon>
          <UilComment />
        </template>
      </ButtonClassic>
    </div>
  </section>
</template>

<script>
import config from "@/config";

import { ButtonClassic } from "@lde/core_lde_vue";

import { UilComment } from "@iconscout/vue-unicons";

import { mapGetters } from "vuex";

/**
 * Affiche la technologie requise pour le produit.
 */
export default {
  name: "ManuelNumeriqueRequired",
  components: {
    ButtonClassic,
    UilComment,
  },
  props: {
    /**
     * Produit affiché.
     */
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    config() {
      return config;
    },
    ...mapGetters(["isGE", "urlHotline", "country"]),
    orderedSupports() {
      if (this.product.technologie) {
        const supports = this.product.technologie.supports;
        const res = {
          Windows: supports.find((s) => s.support.libelle === "Windows") || {},
          MacOS: supports.find((s) => s.support.libelle === "MacOS") || {},
          "iOS tablette": supports.find((s) => s.support.libelle === "iOS tablette") || {},
          "Android tablette": supports.find((s) => s.support.libelle === "Android tablette") || {},
        };
        return res;
      }
      return null;
    },
    firstSupport() {
      if (this.product.technologie) {
        return this.product.technologie.supports.find((sup) => sup.support.libelle === "Windows");
      }
      return null;
    },
    isDe() {
      return this.country === "de";
    },
    btnAssistanceAttr() {
      const attr = {};
      if (!this.isDe) {
        attr.baliseType = "a";
        attr.extLink = this.urlHotline;
      }
      return attr;
    },
    mailLde() {
      return config.mails.lde;
    },
  },
  methods: {
    customCopyToClipboard(text, title = null) {
      const el = document.createElement("textarea");
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.success({ title: title || this.$t("toast.element-copie-presse-papier") });
    },
    copyMailLde() {
      if (this.isDe) {
        this.customCopyToClipboard(
          this.mailLde, this.$tc("general.email-copiee", 0, { mail: this.mailLde }),
        );
      }
    },
  },
};
</script>

<style lang="scss">
@use "~@lde/core_lde_vue/dist/assets/styles/_variables.scss" as var;

#product_required {
  grid-column: 2 / -2;
  margin-bottom: var.$space-xx-large;

  h2 { margin-bottom: var.$space-tiny; }

  > div {
    display: flex;
    justify-content: space-between;
  }

  ul {
    margin-right: var.$space-x-tiny;
    color: var.$gray-medium;
  }

  .button-classic { align-self: flex-end;}
}
</style>
